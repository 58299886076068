import './index.css'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { logger } from './utils/logger'
import App from './App.tsx'

// Wait for DOM content to be loaded
document.addEventListener('DOMContentLoaded', () => {
  try {
    logger.log('Application starting...');
    logger.log('Environment:', {
      isDev: import.meta.env.DEV,
      isProd: import.meta.env.PROD,
      enableLogging: import.meta.env.VITE_ENABLE_LOGGING
    });

    const rootElement = document.getElementById('root');
    if (!rootElement) {
      throw new Error('Failed to find root element');
    }

    createRoot(rootElement).render(
      <StrictMode>
        <App />
      </StrictMode>,
    );
  } catch (error) {
    console.error('Failed to start application:', error);
  }
});
