import React, { useState, useRef, useEffect } from "react";
import {
  SurveyData,
  SurveyQuestion,
  SurveyAnswer,
  AnalysisResult,
  UserData,
} from "../types";
import { analyzeResults } from "../utils/analysis";
import { logger } from "../utils/logger";
import Results from "./Results";
import Tooltip from "./Tooltip";
import { v4 as uuidv4 } from 'uuid';
import { config } from '../config/environment';

// Section 1: Questions 1,2,5,6 only
const section1Questions: SurveyQuestion[] = [
  {
    id: 1,
    text: "You attend a networking lunch and are assigned to sit at a table of 10 people whom you do not know. Which social approach do you prefer?",
    optionA:
      "Someone suggests an ice breaker where people introduce themselves by sharing a fun fact and their current company. Everyone is encouraged to engage in the conversation.",
    optionB:
      "Someone beside you introduces themself. They ask you about yourself and seem to want to get to you know more. The table consists of several of these one-on-one conversations.",
    onSelect: (option: "A" | "B") => {
      logger.log(`1.${option.toLowerCase()}`);
    }
  },
  {
    id: 2,
    text: "You work with a non-profit program and are assisting a coworker in developing guidelines for interviewing volunteers. Which coworker would you prefer to work with?",
    optionA:
      "Someone who adopts a generalized approach to 'cut the red tape,' thus allowing the interviewers to be more flexibile and adaptable. Increased autonomy will result in a more efficient recruiting process.",
    optionB:
      "Someone who adopts a detailed and thorough approach to ensure accuracy, thus increasing recruiting efficiency. The entire process will be documented and used as a reference by others.",
    onSelect: (option: "A" | "B") => {
      logger.log(`2.${option.toLowerCase()}`);
    }
  },
  {
    id: 3,
    text: "You are invited to a friend's wedding. Which type of social wedding atmosphere would you prefer?",
    optionA:
      "Vibrant music and boisterous, lighthearted, funny toasts that allow guests to share in the couple's celebration. You enjoy casual mingling and may keep it brief to interact with more people.",
    optionB:
      "Intimate, engaging atmosphere and sincere toasts. You enjoy one-on-one conversations where a deeper connection may exist. You may keep it brief with people you don't know well.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.${option.toLowerCase()}`);
    }
  },
  {
    id: 4,
    text: "A team of students is seeking your advice on a group presentation. Which group do you prefer to advise?",
    optionA:
      "A group where each person wants to be assigned a specific task and process they must complete thoroughly. They dont want to miss any details, though might end up doing some extra work.",
    optionB:
      "A group who wants help identifying the top factors that may result in the most sizeable impact. Members can complete the work how they see fit, but this approach might miss some important details.",
    onSelect: (option: "A" | "B") => {
      logger.log(`4.${option.toLowerCase()}`);
    }
  },
];

// Section 2: Questions 9,10,13,14,17,18,21,22 only
const section2Questions: SurveyQuestion[] = [
  {
    id: 5,
    text: "Which of these skills comes most naturally to you?",
    optionA:
      "Understanding and adjusting the emotional atmosphere of a group. Ex. Bringing excitement to a group at a birthday party as you tend to be able to read the room and adapt accordingly.",
    optionB:
      "Understanding and adjusting highly complicated logical structures. Ex. Learning the principles behind income taxes as it follows complex, yet repeatable patterns.",
    onSelect: (option: "A" | "B") => {
      logger.log(`5.${option.toLowerCase()}`);
    }
  },
  {
    id: 6,
    text: "Which of these skills comes most naturally to you?",
    optionA:
      "Understanding and adjusting the emotional distance of your individual relationships. Ex. Expressing a specific opinion to your best friend in order to feel closer.",
    optionB:
      "Understanding and adjusting specific components in a system to increase efficiency. Ex. Preparing ingredients and utensils to streamline cooking and cleaning.",
    onSelect: (option: "A" | "B") => {
      logger.log(`6.${option.toLowerCase()}`);
    }
  },
  {
    id: 7,
    text: "Which of these skills comes most naturally to you?",
    optionA:
      "Communicating with other people through adjusting your tone-of-voice and facial expressions. Ex. Knowing when to use a soft or firm tone in order to encourage a team that is falling behind.",
    optionB:
      "Communicating with other people through consistent explanations and systematic logical principles. Ex. Explaining the concepts and rules of a game to a group of people who haven't played it before.",
    onSelect: (option: "A" | "B") => {
      logger.log(`7.${option.toLowerCase()}`);
    }
  },
  {
    id: 8,
    text: "Which of these skills comes most naturally to you?",
    optionA:
      "Interpreting your opinions and how those opinions make you feel. Ex. Considering how a house in a neighborhood makes you feel to determine an ideal house to buy.",
    optionB:
      "Interpreting data points and individual facts. Ex. Reviewing an article about neighborhood amenities, Home Owner Association rules, and taxes to determine an ideal house to buy.",
    onSelect: (option: "A" | "B") => {
      logger.log(`8.${option.toLowerCase()}`);
    }
  },
  {
    id: 9,
    text: "Which type of advice are you more naturally skilled at providing?",
    optionA:
      "How to join social groups and engage in group activities. Ex. Advising a friend on some rules-of-thumb to make connections at an upcoming wedding they plan to attend, such as scanning the room to see which groups are interesting before joining their conversation.",
    optionB:
      "How to understand the frameworks and principles of the logistics involved in travel. Ex. Advising a friend on how to plan travel to an upcoming wedding they plan to attend, such as providing a travel checklist, a budget, and ensuring they have the appropriate identification.",
    onSelect: (option: "A" | "B") => {
      logger.log(`9.${option.toLowerCase()}`);
    }
  },
  {
    id: 10,
    text: "Which type of advice are you more naturally skilled at providing?",
    optionA:
      "How to develop a close relationship in order to make it more valuable. Ex. Advising a coworker on how to pull back from a friendship that has been negative for them.",
    optionB:
      "How to identify and compare business or economic opportunities. Ex. Advising a coworker on how to compare different 401k investment options.",
    onSelect: (option: "A" | "B") => {
      logger.log(`10.${option.toLowerCase()}`);
    }
  },
  {
    id: 11,
    text: "Which type of advice are you more naturally skilled at providing?",
    optionA:
      "Managing social groups and the expectations of its members. Ex. Advising a friend on how to reduce social friction in a challenging friend group.",
    optionB:
      "Managing personal finances by following the widely accepted rules and principles. Ex. Advising a friend on how to improve their financial wellness by following strategies of budgeting, spending, and saving.",
    onSelect: (option: "A" | "B") => {
      logger.log(`11.${option.toLowerCase()}`);
    }
  },
  {
    id: 12,
    text: "Which type of advice are you more naturally skilled at providing?",
    optionA:
      "Managing the nuances and situational details of a close relationship. Ex. Advising a family member on how to use their personality traits to better connect with another family member.",
    optionB:
      "Managing personal finances through leveraging unique opportunities. Ex. Advising a family member on how to to take advantage of a recent change in the housing market.",
    onSelect: (option: "A" | "B") => {
      logger.log(`12.${option.toLowerCase()}`);
    }
  },
];

// Section 2.1: Questions 9,10,13,14,17,18,21,22 only
const section2_1Questions: Record<string, SurveyQuestion> = {
  "2_1_1": {
    id: 2.11,
    text: "While I am skilled at emotionally engaging with friends and tailoring my expressions in an encouraging and helpful manner, the following best describes my approach to helping a friend who is feeling down:",
    optionA: "I often find it unnecessary to dive into the specifics of their mood, instead relying on my engaging demeanor whenever possible", //Cats + 1
    optionB: "I often ask specifics around their mood and provide subtle advice for them to reflect on", //Dogs + 1
    onSelect: (option: "A" | "B") => {
      logger.log(`2.11.${option.toLowerCase()}`);
    }
  },
  "2_1_2": {
    id: 2.12,
    text: "When faced with a task that may take a few hours to complete, the following best describes my approach:",
    optionA: "My natural mode of operation is to take a step back to evaluate the structure, organization, rules and big picture of the work I need to accomplish—even if it is a short task that I'm already familiar with. I first focus on putting a solid plan in place before focusing on the specifics of the tasks or components of the plan.", //Lions + 1
    optionB: "My natural mode of operation is to consider a few of the most important details of my tasks, or complete a few of the highest priority tasks first to then structure my plan around those points. I often act quickly if it is a task I am familiar with. I focus on the details of the task and the 'why' behind them to better understand the big picture.", //Wolves + 1
    onSelect: (option: "A" | "B") => {
      logger.log(`2.12.${option.toLowerCase()}`);
    }
  },
  "2_1_3": {
    id: 2.13,
    text: "While I am skilled at understanding others' feelings and can tailor my interaction accordingly, the following best describes my approach to helping a friend who is  feeling down:",
    optionA: "I often find it unnecessary to adjust my own emotional state or otherwise try to alter their mood through my own expressions, but I can do both if needed. It may take time, but I can help them as I listen and learn more details of their situation", //Dogs + 1
    optionB: "I often adjust my own emotional expressions to impact an individual or even a small group, even if it is not something I do intentionally. I excel at providing", //Cats + 1
    onSelect: (option: "A" | "B") => {
      logger.log(`2.13.${option.toLowerCase()}`);
    }
  },
  "2_1_4": {
    id: 2.14,
    text: "When faced with a task that may take a few hours to complete, the following best describes my approach:",
    optionA: "My natural mode of operation is to quickly evaluate the work I need to accomplish, and I often find it unnecessary to slow down to consider my task within a broader context. I am skilled at doing both, but more so in making quick decisions when it is needed.", //Wolves + 1
    optionB: "My natural mode of operation is to carefully evaluate work I need to accomplish, and I often find it useful to slow down to consider my task within a broader context. I am skilled at doing both, but more so in stepping back to see how the parts make up the whole.", //Lions + 1
    onSelect: (option: "A" | "B") => {
      logger.log(`2.14.${option.toLowerCase()}`);
    }
  },
};

// Section 3: Branching questions
const section3Questions: Record<number, SurveyQuestion> = {
  3.1: {
    id: 3.1,
    text: "To increase group engagement and motivation, which approach do you prefer?",
    optionA:
      "Engage with (or become) the most influential person in a team to get buy-in for a specific goal, ultimately helping the entire team align toward a central purpose.",
    optionB:
      "Help align the group's mood with the appropriate occasion while considering the hospitality of the event. You ensure everyone's comfort though having snacks, a conducive environment for the activity at hand or thoughtful, personalized touches for the group.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.1.${option.toLowerCase()}`);
    }
  },
  3.2: {
    id: 3.2,
    text: "2.	When joining a large team, which approach you prefer?",
    optionA:
      "Apply learned or intuitive rules on how to behave depending on who you are around. Attach yourself to the key players within a group in order to influence their motivations and goals.",
    optionB:
      "Always follow appropriate etiquette. Maintain awareness of who you are engaging with and be flexible to their needs. Provide the team whatever is needed—such as a positive attitude, decision-making, or some kind words.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.2.${option.toLowerCase()}`);
    }
  },
  3.3: {
    id: 3.3,
    text: "When organizing a brainstorming session to solve a complex problem, which approach do you prefer?",
    optionA:
      "Allow the experts to speak first thus reducing unnecessary speculation toward solving the problem. Any additional ideas can be brought to the experts beforehand.",
    optionB:
      "Encourage discussion to allow sharing on many perspectives. Rushing this time may result in important considerations being left out. Carefully consider how the perspectives and ideas all fit together in the big picture.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.3.${option.toLowerCase()}`);
    }
  },
  3.4: {
    id: 3.4,
    text: "In order to provide a vision for a team to follow, which approach do you prefer?",
    optionA:
      "Gather all necessary data points to understand what is possible, entirely reasonable, and immediately actionable. Involve experts. Document a detailed and specific plan with defined milestones.",
    optionB:
      "Ensure the plan has enough flexibility to integrate multiple paths forward. Bounce ideas off others, including experts. Document many ideas: we will refine it as we go.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.4.${option.toLowerCase()}`);
    }
  },
  3.5: {
    id: 3.5,
    text: "To provide motivation and purpose to an individual, which approach do you prefer?",
    optionA:
      "By listening and understanding a person's goals I can encourage specific, immediate and decisive action.",
    optionB:
      "By offering several new, creative ideas and encouraging unique approaches to problems, maybe I can energize a person into action.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.5.${option.toLowerCase()}`);
    }
  },
  3.6: {
    id: 3.6,
    text: "To call out individuals who are inappropriately influencing a group, which approach do you prefer?",
    optionA:
      "By directly asking the individual why they are suggesting an action, I can prompt the individual to explain their intentions and show the group that the individual may have a specific motivation.",
    optionB:
      "By providing alternative perspectives to what the influencer is suggesting I can present viable alternatives or even highlight their motivation for everyone in the group to assess.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.6.${option.toLowerCase()}`);
    }
  },
  3.7: {
    id: 3.7,
    text: "Which approach do you prefer for household organization and cleanliness",
    optionA:
      " Home efficiency is important to me. I want to have quick and easy access to commonly-used items. The aesthetics of how this is done is prioritized much lower than the organization itself, and will rarely spend time cleaning or organizing otherwise. I might invest time if I know guests will be coming over.",
    optionB:
      "My home is my sanctuary and I keep it organized to give me peace of mind. Displaying meaningful experiences such as vacation photographs, trinkets & mementos, or favorite board games is the difference between a house and a home.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.7.${option.toLowerCase()}`);
    }
  },
  3.8: {
    id: 3.8,
    text: "To understand your self and your growth path, which approach do you prefer?",
    optionA:
      "The steps I take now toward immediate milestones will dictate my long-term future outcome and play an important role in who I will become. This planning is enjoyable.",
    optionB:
      "My lineage, historic situation, and the path my family took all play an important role in who I am and who I will become. Diving into these topics is fascinating.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.8.${option.toLowerCase()}`);
    }
  },
};

interface SurveyProps {
  userData: UserData & {
    name: string;
  };
  onComplete?: (data: SurveyData) => void;
}

const Survey: React.FC<SurveyProps> = ({ userData, onComplete }) => {
  const [currentSection, setCurrentSection] = useState(1);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<SurveyAnswer[]>([]);
  const [results, setResults] = useState<AnalysisResult | null>(null);
  const [part1, setPart1] = useState<"A" | "B" | null>(null);
  const [part2, setPart2] = useState<"wide" | "small" | null>(null);
  const [part3, setPart3] = useState<"A" | "B" | "C" | null>(null);
  const [questionFeedback, setQuestionFeedback] = useState<
    "easy" | "difficult" | null
  >(null);
  const [difficultyFeedback, setDifficultyFeedback] = useState<string>("");
  const feedbackRef = useRef<HTMLDivElement>(null);

  // Add ref for Next button
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const part1Ref = useRef<HTMLDivElement>(null);
  const part2Ref = useRef<HTMLDivElement>(null);
  const part3Ref = useRef<HTMLDivElement>(null);

  // Function to check if an element is fully visible
  const isElementFullyVisible = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  // Function to scroll to element if not fully visible
  const scrollToIfNeeded = (element: HTMLElement | null) => {
    if (element && !isElementFullyVisible(element)) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };

  const handleQuestionFeedback = (feedback: "easy" | "difficult") => {
    logger.log('Question feedback selected:', feedback);
    setQuestionFeedback(feedback);
    scrollToIfNeeded(nextButtonRef.current);
  };

  // Add loading state
  const [isSyncing, setIsSyncing] = useState(false);

  // Update syncLocalStorage to use loading state
  const syncLocalStorage = async () => {
    try {
      setIsSyncing(true);
      const logs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
      if (logs.length > 0) {
        logger.log('Sending bulk logs:', logs);

        const response = await fetch('/api/telemetry/bulk', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any auth headers if needed
          },
          body: JSON.stringify({ logs }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(`API Error: ${data.error} - ${data.details}`);
        }

        // Only clear logs if the request was successful
        localStorage.removeItem('surveyLogs');
        logger.log('Successfully synced local logs');
      }
    } catch (error) {
      logger.error('Error syncing local logs:', error);
      // Don't remove logs on error so we can retry later
    } finally {
      setIsSyncing(false);
    }
  };

  // Add loading state
  const [isSendingTelemetry, setIsSendingTelemetry] = useState(false);

  const validateTelemetryData = (data: any) => {
    let isValid = true;
    if (!data.userData?.name) {
      logger.warn('Warning: Invalid telemetry data - missing userData.name:', data);
      isValid = false;
    }
    if (!data.message_name || !data.questionId) {
      logger.warn('Warning: Invalid telemetry data - missing required fields:', data);
      isValid = false;
    }
    return isValid;
  };

  // Update logTelemetry function to not block on validation failure
  const logTelemetry = async (answer: SurveyAnswer) => {
    logger.log('logTelemetry called with answer:', answer);
    setIsSendingTelemetry(true);
    try {
      const telemetryData = {
        userData: {
          name: userData.name,
          accessCode: userData.accessCode,
          age: userData.age || 0,
          gender: userData.gender || '',
          occupation: userData.occupation || '',
          maritalStatus: userData.maritalStatus || ''
        },
        message_name: 'answer_submitted' as const,
        questionId: String(answer.questionId),
        part1: answer.part1,
        part2: answer.part2,
        part3: answer.part3,
        questionFeedback: answer.questionFeedback,
        difficultyFeedback: answer.difficultyFeedback || '',
        timestamp: new Date().toISOString(),
        metadata: {
          sessionId: sessionId,
          browser: navigator.userAgent,
          url: window.location.href
        }
      };

      logger.log('Attempting to send telemetry:', telemetryData);

      try {
        logger.log('Making fetch request to /api/telemetry');
        const response = await fetch('/api/telemetry', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(telemetryData),
        });

        const responseText = await response.text();
        logger.log('Telemetry response:', {
          status: response.status,
          ok: response.ok,
          text: responseText,
          headers: Object.fromEntries(response.headers.entries())
        });

        if (!response.ok) {
          throw new Error(`API call failed: ${response.status} - ${responseText}`);
        }
      } catch (error) {
        logger.error('API call error:', error);
        // Store in localStorage for retry
        const existingLogs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
        existingLogs.push(telemetryData);
        localStorage.setItem('surveyLogs', JSON.stringify(existingLogs));
        logger.log('Stored telemetry in localStorage for retry later');
      }
    } catch (error) {
      logger.error('Telemetry error:', error);
    } finally {
      setIsSendingTelemetry(false);
    }
  };

  // Modify handleNext to properly handle section transitions
  const handleNext = async () => {
    logger.log('handleNext called with current state:', {
      currentSection,
      currentQuestion,
      part1,
      part2,
      part3,
      questionFeedback,
      difficultyFeedback
    });

    if (!part1 || !part2 || !part3) {
      logger.warn('Missing answer parts:', { part1, part2, part3 });
      return;
    }

    const question = getCurrentQuestion();
    if (!question) {
      logger.error('No current question found');
      return;
    }

    logger.log('Current question:', question);

    const newAnswer: SurveyAnswer = {
      questionId: question.id,
      part1,
      part2,
      part3,
      questionFeedback,
      difficultyFeedback: questionFeedback === "difficult" ? difficultyFeedback : "",
    };

    logger.log('Created new answer:', newAnswer);

    try {
      logger.log('Sending telemetry...');
      await logTelemetry(newAnswer);
      logger.log('Telemetry sent successfully');
    } catch (error) {
      logger.error('Failed to send telemetry:', error);
    }

    setAnswers(prevAnswers => {
      logger.log('Adding answer to state:', newAnswer);
      return [...prevAnswers, newAnswer];
    });

    // Reset form
    setPart1(null);
    setPart2(null);
    setPart3(null);
    setQuestionFeedback(null);
    setDifficultyFeedback("");
    logger.log('Form state reset');

    // Navigation logic with logging
    const currentQuestions = getCurrentSectionQuestions();
    logger.log('Navigation state:', {
      currentSection,
      currentQuestion,
      totalQuestions: currentQuestions.length
    });

    // Navigation logic
    if (currentQuestion < currentQuestions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else if (currentSection === 2) {
      const partialResults = analyzeResults({
        answers: [...answers, newAnswer],
      });
      const mpoldeScore =
        Math.abs(partialResults.s2dogs) +
        Math.abs(partialResults.s2wolves) -
        (Math.abs(partialResults.s2cats) + Math.abs(partialResults.s2lions));

      if (mpoldeScore === 0) {
        setCurrentSection(2.1);
      } else {
        setCurrentSection(3);
      }
      setCurrentQuestion(0);
    } else if (currentSection === 2.1) {
      setCurrentSection(3);
      setCurrentQuestion(0);
    } else if (currentSection < 3) {
      setCurrentSection(currentSection + 1);
      setCurrentQuestion(0);
    } else {
      const analysisResult = analyzeResults({
        answers: [...answers, newAnswer],
      });
      setResults(analysisResult);
    }

    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getCurrentSectionQuestions = () => {
    logger.log('Getting questions for section:', currentSection);

    if (currentSection === 1) {
      logger.log('Returning section 1 questions');
      return section1Questions;
    }
    if (currentSection === 2) {
      logger.log('Returning section 2 questions');
      return section2Questions;
    }

    if (currentSection === 2.1) {
      const partialResults = analyzeResults({ answers: answers.slice(0, 12) });
      const { QPrefD, QStrD, MPolDe } = partialResults;

      const mpoldeScore =
        Math.abs(partialResults.s2dogs) +
        Math.abs(partialResults.s2wolves) -
        (Math.abs(partialResults.s2cats) + Math.abs(partialResults.s2lions));

      if (mpoldeScore === 0) {
        if (QPrefD === "Feline" && QStrD === "Pets")
          return [section2_1Questions["2_1_1"]];
        if (QPrefD === "Feline" && QStrD === "Wild")
          return [section2_1Questions["2_1_2"]];
        if (QPrefD === "Canine" && QStrD === "Pets")
          return [section2_1Questions["2_1_3"]];
        if (QPrefD === "Canine" && QStrD === "Wild")
          return [section2_1Questions["2_1_4"]];
      }
      return [];
    }

    const partialResults = analyzeResults({ answers });
    const { QPrefD, QStrD } = partialResults;

    if (QPrefD === "Feline" && QStrD === "Pets")
      return [section3Questions[3.1], section3Questions[3.2]];
    if (QPrefD === "Feline" && QStrD === "Wild")
      return [section3Questions[3.3], section3Questions[3.4]];
    if (QPrefD === "Canine" && QStrD === "Pets")
      return [section3Questions[3.5], section3Questions[3.6]];
    if (QPrefD === "Canine" && QStrD === "Wild")
      return [section3Questions[3.7], section3Questions[3.8]];
    return [];
  };

  const getCurrentQuestion = (): SurveyQuestion => {
    const questions = getCurrentSectionQuestions();
    const question = questions[currentQuestion];
    logger.log('Current question:', question);
    return question;
  };

  const getTooltipText = (section: number, part: number) => {
    if (section === 1) {
      switch (part) {
        case 1:
          return "Assume both choices are equally viable. All your basic needs are met, and there are no consequences or constraints. Which would you prefer to do?";
        case 2:
          return "Wide gap means you strongly prefer your selection over the other, small gap means the two choices are close in preference.";
        case 3:
          return "Thinking back to when you were younger, like in your early 20's, would you have answered differently?";
      }
    } else if (section === 2) {
      switch (part) {
        case 1:
          return "Skill means your ability to perform these actions with competence and completeness. A natural skill is one that does not require extra effort to do well";
        case 2:
          return "Wide gap means you are much more skilled in your selection over the other, small gap means the two choices are close in skill.";
        case 3:
          return "Thinking back to when you were younger, like in your early 20's, would you have answered differently?";
      }
    } else {
      switch (part) {
        case 1:
          return "Assume both choices are equally viable. All your basic needs are met, and there are no consequences or constraints. Which would you prefer to do?";
        case 2:
          return "Wide gap means you strongly prefer your selection over the other, small gap means the two choices are close in preference.";
        case 3:
          return "Thinking back to when you were younger, like in your early 20's, would you have answered differently?";
      }
    }
    return "";
  };

  // Add useEffect for online/offline detection and sync
  useEffect(() => {
    // Function to handle coming online
    const handleOnline = async () => {
      logger.log('Connection restored, attempting to sync...');
      await syncLocalStorage();
    };

    // Add event listeners
    window.addEventListener('online', handleOnline);

    // Check if we have unsent logs on mount
    const checkPendingLogs = async () => {
      if (navigator.onLine) {
        const logs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
        if (logs.length > 0) {
          logger.log('Found pending logs, attempting to sync...');
          await syncLocalStorage();
        }
      }
    };
    checkPendingLogs();

    // Cleanup
    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  // Update DEV_TOOLS to show loading state
  const DEV_TOOLS = import.meta.env.DEV ? (
    <div className="fixed bottom-4 right-4 space-y-2">
      <button
        onClick={() => {
          const logs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
          logger.log('Current logs:', logs);
        }}
        className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
      >
        View Logs
      </button>
      <button
        onClick={() => {
          localStorage.removeItem('surveyLogs');
          logger.log('Logs cleared');
        }}
        className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600 block"
      >
        Clear Logs
      </button>
      <button
        onClick={syncLocalStorage}
        disabled={isSyncing}
        className={`${isSyncing
          ? 'bg-gray-500 cursor-not-allowed'
          : 'bg-green-500 hover:bg-green-600'
          } text-white px-4 py-2 rounded shadow block relative`}
      >
        {isSyncing ? (
          <>
            <span className="opacity-0">Force Sync</span>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full" />
            </div>
          </>
        ) : (
          'Force Sync'
        )}
      </button>
    </div>
  ) : null;

  useEffect(() => {
    try {
      logger.log('Survey mounted with userData:', userData);
      if (!userData?.name) {
        logger.error('Survey received invalid userData:', userData);
      }
    } catch (error) {
      console.error('Error in Survey mount:', error);
    }
  }, [userData]);

  const [sessionId] = useState(() => uuidv4());

  const handleAnswerSelect = (part: 'part1' | 'part2' | 'part3', value: any) => {
    logger.log(`Setting ${part} to:`, value);
    switch (part) {
      case 'part1':
        setPart1(value);
        break;
      case 'part2':
        setPart2(value);
        break;
      case 'part3':
        setPart3(value);
        break;
    }
  };

  const sendQuestionStartedTelemetry = async (questionId: string | number) => {
    try {
      await fetch('/api/telemetry/question-started', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId,
          name: userData.name,
          questionId: String(questionId)
        }),
      });
    } catch (error) {
      console.error('Failed to send question started telemetry:', error);
    }
  };

  // Update useEffect to send telemetry when question changes
  useEffect(() => {
    const question = getCurrentQuestion();
    if (question) {
      sendQuestionStartedTelemetry(question.id);
    }
  }, [currentSection, currentQuestion]);

  if (results) {
    return <Results
      results={results}
      onFeedbackSubmit={() => { }}
      userData={{
        name: userData.name,
        sessionId: sessionId
      }}
    />;
  }

  const currentQuestions = getCurrentSectionQuestions();
  const question = getCurrentQuestion();
  const progress =
    ((currentSection - 1) * 100 +
      (currentQuestion + 1) * (100 / currentQuestions.length)) /
    3;

  const getButtonClass = (type: "part1" | "part2" | "part3", value: string) => {
    const baseClass =
      "w-full text-sm font-medium p-4 rounded-2xl transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 whitespace-normal text-left break-words ";

    switch (type) {
      case "part1":
        return (
          baseClass +
          (part1 === value
            ? "bg-blue-600 text-white shadow-lg ring-2 ring-blue-400"
            : "bg-gray-700 text-gray-300 hover:bg-gray-600 focus:ring-blue-400")
        );
      case "part2":
        return (
          baseClass +
          (part2 === value
            ? "bg-green-600 text-white shadow-lg ring-2 ring-green-400"
            : "bg-gray-700 text-gray-300 hover:bg-gray-600 focus:ring-green-400")
        );
      case "part3":
        return (
          baseClass +
          (part3 === value
            ? "bg-purple-600 text-white shadow-lg ring-2 ring-purple-400"
            : "bg-gray-700 text-gray-300 hover:bg-gray-600 focus:ring-purple-400")
        );
    }
  };

  const handleAnswerPart1 = (value: "A" | "B") => {
    logger.log('Part 1 selected:', value);
    const question = getCurrentQuestion();
    question.onSelect?.(value);
    setPart1(value);
    scrollToIfNeeded(part2Ref.current);
  };

  const handleAnswerPart2 = (value: "wide" | "small") => {
    logger.log('Part 2 selected:', value);
    setPart2(value);
    scrollToIfNeeded(part3Ref.current);
  };

  const handleAnswerPart3 = (value: "A" | "B" | "C") => {
    logger.log('Part 3 selected:', value);
    setPart3(value);
    scrollToIfNeeded(nextButtonRef.current);
  };

  const DEBUG_PANEL = config.enableDebugPanel ? (
    <div className="fixed top-4 left-4 bg-gray-800 p-4 rounded shadow text-white text-sm">
      <pre>
        {JSON.stringify(
          {
            section: currentSection,
            question: currentQuestion,
            part1,
            part2,
            part3,
            feedback: questionFeedback,
          },
          null,
          2
        )}
      </pre>
    </div>
  ) : null;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 p-4">
      {DEBUG_PANEL}
      {isSendingTelemetry && (
        <div className="fixed top-4 right-4 text-sm text-gray-400">
          Saving response...
        </div>
      )}
      <div id="survey-container" className="max-w-2xl w-full mx-auto p-6 bg-gray-800 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-white">
          Question {currentQuestion + 1} of{" "}
          {getCurrentSectionQuestions().length}
        </h2>

        {/* Part 1 */}
        <div ref={part1Ref} className="space-y-6 mb-8">
          <div>
            <Tooltip text={getTooltipText(currentSection, 1)}>
              <p className="mb-3 font-semibold text-gray-300">
                Part 1: Choose A or B
              </p>
            </Tooltip>
            <p className="mb-4 text-lg text-gray-300">{question.text}</p>
            <div className="grid grid-cols-1 gap-3">
              <button
                onClick={() => handleAnswerPart1("A")}
                className={getButtonClass("part1", "A")}
              >
                <span className="block">A) {question.optionA}</span>
              </button>
              <button
                onClick={() => handleAnswerPart1("B")}
                className={getButtonClass("part1", "B")}
              >
                <span className="block">B) {question.optionB}</span>
              </button>
            </div>
          </div>
        </div>

        {/* Part 2 */}
        <div ref={part2Ref} className="space-y-6 mb-8">
          <div>
            <Tooltip text={getTooltipText(currentSection, 2)}>
              <p className="mb-3 font-semibold text-gray-300">
                Part 2: How wide is the gap?
              </p>
            </Tooltip>
            <div className="flex gap-3">
              <button
                onClick={() => handleAnswerPart2("wide")}
                className={`${part2 === "wide"
                  ? "bg-green-600 text-white shadow-lg ring-2 ring-green-400"
                  : "bg-gray-700 text-gray-300 hover:bg-gray-600 focus:ring-green-400"
                  } px-8 py-4 rounded-2xl transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 min-w-[120px] w-fit`}
              >
                Wide
              </button>
              <button
                onClick={() => handleAnswerPart2("small")}
                className={`${part2 === "small"
                  ? "bg-green-600 text-white shadow-lg ring-2 ring-green-400"
                  : "bg-gray-700 text-gray-300 hover:bg-gray-600 focus:ring-green-400"
                  } px-8 py-4 rounded-2xl transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 min-w-[120px] w-fit`}
              >
                Small
              </button>
            </div>
          </div>
        </div>

        {/* Part 3 */}
        <div ref={part3Ref} className="space-y-6 mb-8">
          <div>
            <Tooltip text={getTooltipText(currentSection, 3)}>
              <p className="mb-3 font-semibold text-gray-300">
                Part 3: Has your answer changed?
              </p>
            </Tooltip>
            <div className="grid grid-cols-3 gap-3">
              <button
                onClick={() => handleAnswerPart3("A")}
                className={getButtonClass("part3", "A")}
              >
                <span className="px-2">A) Not changed</span>
              </button>
              <button
                onClick={() => handleAnswerPart3("B")}
                className={getButtonClass("part3", "B")}
              >
                <span className="px-2">B) Used to choose opposite</span>
              </button>
              <button
                onClick={() => handleAnswerPart3("C")}
                className={getButtonClass("part3", "C")}
              >
                <span className="px-2">C) Not sure</span>
              </button>
            </div>
          </div>
        </div>

        {/* Optional Feedback Section */}
        <div ref={feedbackRef} className="space-y-6 mb-8">
          <div>
            <p className="mb-3 font-semibold text-gray-300">
              Optional: How easy was this question to understand and answer?
            </p>
            <div className="flex gap-3 items-start">
              <button
                onClick={() => {
                  handleQuestionFeedback("easy");
                  setDifficultyFeedback(""); // Clear any existing feedback
                }}
                className={`text-sm font-medium p-4 rounded-2xl transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 whitespace-normal flex items-center gap-2 min-w-[120px] w-fit ${questionFeedback === "easy"
                  ? "bg-teal-600 text-white shadow-lg ring-2 ring-teal-400"
                  : "bg-gray-700 text-gray-300 hover:bg-gray-600 focus:ring-teal-400"
                  }`}
              >
                <span>Easy</span>
                <span role="img" aria-label="smiley face" className="text-2xl">
                  😊
                </span>
              </button>
              <div className="flex gap-3 items-start">
                <button
                  onClick={() => handleQuestionFeedback("difficult")}
                  className={`text-sm font-medium p-4 rounded-2xl transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 whitespace-normal flex items-center gap-2 min-w-[120px] w-fit ${questionFeedback === "difficult"
                    ? "bg-teal-600 text-white shadow-lg ring-2 ring-teal-400"
                    : "bg-gray-700 text-gray-300 hover:bg-gray-600 focus:ring-teal-400"
                    }`}
                >
                  <span>Difficult</span>
                  <span role="img" aria-label="confused face" className="text-2xl">
                    😕
                  </span>
                </button>
                {questionFeedback === "difficult" && (
                  <div className="flex-1 ml-3">
                    <input
                      type="text"
                      value={difficultyFeedback}
                      onChange={(e) => setDifficultyFeedback(e.target.value)}
                      placeholder="How so?"
                      className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg border border-gray-600 focus:outline-none focus:ring-2 focus:ring-teal-400"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 bg-gray-700 rounded-full h-2">
          <div
            className="bg-blue-600 h-2 rounded-full transition-all duration-300 ease-in-out"
            style={{ width: `${progress}%` }}
          />
        </div>

        <button
          ref={nextButtonRef}
          onClick={handleNext}
          disabled={!part1 || !part2 || !part3}
          className={`mt-6 w-full ${!part1 || !part2 || !part3
            ? "bg-gray-600 text-gray-400 cursor-not-allowed"
            : "bg-blue-600 text-white hover:bg-blue-700"
            } font-medium py-3 px-4 rounded-full transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 focus:ring-offset-gray-800`}
        >
          Next
        </button>
      </div>
      {DEV_TOOLS}
    </div>
  );
};

export default Survey;
