import React, { useState, useEffect } from 'react';
import LandingPage from './components/LandingPage';
import Survey from './components/Survey';
import Results from './components/Results';
import Feedback from './components/Feedback';
import { UserData, SurveyData, AnalysisResult } from './types';
import { analyzeResults } from './utils/analysis';
import ErrorBoundary from './components/ErrorBoundary';


function App() {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [surveyData, setSurveyData] = useState<SurveyData | null>(null);
  const [analysisResult, setAnalysisResult] = useState<AnalysisResult | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check if stylesheets are loaded
    const styleSheets = document.styleSheets;
    if (styleSheets.length > 0) {
      setIsLoading(false);
    } else {
      const interval = setInterval(() => {
        if (document.styleSheets.length > 0) {
          setIsLoading(false);
          clearInterval(interval);
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, []);

  if (isLoading) {
    return null; // Let the HTML loading state show
  }

  const handleUserDataSubmit = (data: UserData) => {
    setUserData(data);
    setStep(2);
  };

  const handleSurveyComplete = (data: SurveyData) => {
    setSurveyData(data);
    const result = analyzeResults(data);
    setAnalysisResult(result);
    setStep(3);
  };

  const handleFeedbackSubmit = (feedback: string) => {
    console.log('Feedback submitted:', feedback);
    setStep(4);
  };

  return (
    <ErrorBoundary>
      <div className="container mx-auto px-4 py-8">
        {step === 1 && <LandingPage onSubmit={handleUserDataSubmit} />}
        {step === 2 && userData && (
          <Survey
            userData={userData}
            onComplete={handleSurveyComplete}
          />
        )}
        {step === 3 && <Results results={analysisResult} onFeedbackSubmit={handleFeedbackSubmit} />}
        {step === 4 && <Feedback />}
      </div>
    </ErrorBoundary>
  );
}

export default App;
