import React, { useState, useEffect } from "react";
import { UserData } from "../types";

interface LandingPageProps {
  onSubmit: (data: UserData) => void;
}

interface UserData {
  name: string;
  email: string;
  accessCode: string;
  age?: number;
  gender?: string;
  occupation?: string;
  maritalStatus?: string;
}

const LandingPage: React.FC<LandingPageProps> = ({ onSubmit }) => {
  const VALID_ACCESS_CODE = "fcfnf2024";

  const [formData, setFormData] = useState<UserData>({
    name: "",
    email: "",
    accessCode: "",
    age: 0,
    gender: "",
    occupation: "",
    maritalStatus: "",
  });

  const [accessError, setAccessError] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === 'name') {
      setFormData((prevData) => ({ ...prevData, [name]: value.trim() }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
    if (name === 'accessCode') {
      setAccessError("");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setAccessError("");
    setEmailError("");

    const trimmedName = formData.name.trim();
    const trimmedEmail = formData.email.trim();

    if (!trimmedName) {
      setAccessError("Please enter your name");
      await sendSurveyStartedTelemetry(false, 'MISSING_FIELD_NAME');
      return;
    }

    if (!trimmedEmail) {
      setEmailError("Please enter your email");
      await sendSurveyStartedTelemetry(false, 'MISSING_FIELD_EMAIL');
      return;
    }

    if (formData.accessCode !== VALID_ACCESS_CODE) {
      setAccessError("Invalid access code. Please try again.");
      await sendSurveyStartedTelemetry(false, 'INVALID_ACCESS_CODE');
      return;
    }

    await sendSurveyStartedTelemetry(true, 'SUCCESS');
    onSubmit({
      ...formData,
      name: trimmedName,
      email: trimmedEmail
    });
  };

  const sendSurveyStartedTelemetry = async (started: boolean, reason: string) => {
    try {
      const sessionId = crypto.randomUUID();
      await fetch('/api/telemetry/survey-started', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId,
          name: formData.name.trim(),
          email: formData.email.trim(),
          accessCode: formData.accessCode,
          started,
          reason
        }),
      });
    } catch (error) {
      console.error('Failed to send survey started telemetry:', error);
    }
  };

  useEffect(() => {
    const sendSessionStartedTelemetry = async () => {
      try {
        const sessionId = crypto.randomUUID();
        const browserMetadata = {
          userAgent: navigator.userAgent,
          language: navigator.language,
          platform: navigator.platform,
          screenResolution: `${window.screen.width}x${window.screen.height}`,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        await fetch('/api/telemetry/session-started', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sessionId,
            browserMetadata
          }),
        });
      } catch (error) {
        console.error('Failed to send session started telemetry:', error);
      }
    };

    sendSessionStartedTelemetry();
  }, []);

  return (
    <div className="max-w-md mx-auto">
      <h1 className="text-2xl font-bold mb-4">
        Welcome to the Four Coins Survey telem
      </h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block mb-1">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="email" className="block mb-1">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
          {emailError && (
            <p className="text-red-500 text-sm mt-1">{emailError}</p>
          )}
        </div>
        <div>
          <label htmlFor="accessCode" className="block mb-1">
            Access Code
          </label>
          <input
            type="text"
            id="accessCode"
            name="accessCode"
            value={formData.accessCode}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
          {accessError && (
            <p className="text-red-500 text-sm mt-1">{accessError}</p>
          )}
        </div>
        {/* Commenting out the other fields for now
        <div>
          <label htmlFor="age" className="block mb-1">Age</label>
          <input
            type="number"
            id="age"
            name="age"
            value={formData.age}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="gender" className="block mb-1">Gender</label>
          <input
            type="text"
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="occupation" className="block mb-1">Occupation</label>
          <input
            type="text"
            id="occupation"
            name="occupation"
            value={formData.occupation}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="maritalStatus" className="block mb-1">Marital Status</label>
          <select
            id="maritalStatus"
            name="maritalStatus"
            value={formData.maritalStatus}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded"
          >
            <option value="">Select...</option>
            <option value="single">Single</option>
            <option value="married">Married</option>
            <option value="divorced">Divorced</option>
            <option value="widowed">Widowed</option>
          </select>
        </div>
        */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Start Survey
        </button>
      </form>
    </div>
  );
};

export default LandingPage;
