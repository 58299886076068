const isDev = import.meta.env.DEV;

export const logger = {
    log: (...args: any[]) => {
        try {
            if (isDev) {
                console.log('[DEBUG]', ...args);
            }
        } catch (error) {
            // Fallback direct console log if something goes wrong
            console.log('[FALLBACK]', ...args);
        }
    },
    error: (...args: any[]) => {
        try {
            if (isDev) {
                console.error('[ERROR]', ...args);
            }
        } catch (error) {
            console.error('[FALLBACK ERROR]', ...args);
        }
    },
    warn: (...args: any[]) => {
        if (isDev) {
            console.warn(...args);
        }
    },
    debug: (...args: any[]) => {
        if (isDev) {
            console.debug(...args);
        }
    }
};

// Add an initialization check
try {
    logger.log('Logger initialized');
} catch (error) {
    console.error('Failed to initialize logger:', error);
} 