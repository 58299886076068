import React, { useState, useRef, useEffect } from 'react';

interface FeedbackPopupProps {
  selectedText: string;
  position: { 
    x: number; 
    y: number;
    containerTop: number;
    containerHeight: number;
    isInAdviceSection?: boolean;
  };
  onClose: () => void;
  onSubmit: (feedback: {
    selectedText: string;
    comments: string;
    agree: boolean;
    disagree: boolean;
  }) => void;
}

const FeedbackPopup: React.FC<FeedbackPopupProps> = ({
  selectedText,
  position,
  onClose,
  onSubmit
}) => {
  const [comments, setComments] = useState('');
  const [agree, setAgree] = useState(false);
  const [disagree, setDisagree] = useState(false);
  const [popupPosition, setPopupPosition] = useState<{x: number, y: number, containerTop: number, containerHeight: number, isInAdviceSection?: boolean}>(position);
  const popupRef = useRef<HTMLDivElement>(null);

  const calculatePosition = () => {
    if (!popupRef.current) return position;

    const popupHeight = popupRef.current.offsetHeight;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    // Center horizontally
    const x = (viewportWidth - 320) / 2; // 320px is the popup width
    
    // Center vertically with scroll position
    const y = window.scrollY + (viewportHeight - popupHeight) / 2;

    return { 
      x, 
      y, 
      containerTop: position.containerTop,
      containerHeight: position.containerHeight,
      isInAdviceSection: position.isInAdviceSection
    };
  };

  useEffect(() => {
    const updatePosition = () => {
      // Use RAF to ensure smooth updates
      requestAnimationFrame(() => {
        const newPosition = calculatePosition();
        setPopupPosition(newPosition);
      });
    };

    // Update position immediately and on every scroll
    updatePosition();
    
    const scrollHandler = () => {
      updatePosition();
    };
    
    window.addEventListener('scroll', scrollHandler, { passive: true });
    window.addEventListener('resize', updatePosition);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('resize', updatePosition);
    };
  }, [position]);

  const handleSubmit = () => {
    onSubmit({
      selectedText,
      comments,
      agree,
      disagree
    });
    onClose();
  };

  const handleAgree = () => {
    setAgree(true);
    setDisagree(false);
  };

  const handleDisagree = () => {
    setDisagree(true);
    setAgree(false);
  };

  return (
    <>
      <div 
        className="fixed inset-0 bg-black bg-opacity-50"
        style={{ zIndex: 999 }}
      />
      <div
        ref={popupRef}
        className="fixed bg-white rounded-lg shadow-xl p-4 w-80"
        style={{
          top: `${popupPosition.y}px`,
          left: `${popupPosition.x}px`,
          zIndex: 1000,
          maxHeight: '90vh',
          overflowY: 'auto'
        }}
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>

        <div className="mb-4 mt-2">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Selected Text
          </label>
          <div className="p-3 bg-gray-50 rounded-md text-gray-800 text-sm border border-gray-200">
            "{selectedText}"
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Additional Comments
          </label>
          <textarea
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows={3}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </div>

        <div className="flex gap-2 mb-4">
          <button
            onClick={handleAgree}
            className={`px-4 py-2 rounded-md ${
              agree
                ? 'bg-green-600 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            I agree
          </button>
          <button
            onClick={handleDisagree}
            className={`px-4 py-2 rounded-md ${
              disagree
                ? 'bg-red-600 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            I disagree
          </button>
        </div>

        <button
          onClick={handleSubmit}
          className="w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
        >
          Submit Feedback
        </button>
      </div>
    </>
  );
};

export default FeedbackPopup; 