interface TypeDescription {
  title: string;
  detailedDescription: string;
  characteristics: string[];
  advice: string;
}

export const typeDescriptionDetails: Record<number, TypeDescription> = {
  1: {
    title: "Type 1 - Alfred (FPE)",
    detailedDescription: `
      As a Type 1 (Alfred), you embody a unique combination of Feline independence, 
      domestic comfort (Pets), and Equine grace. This creates a personality that is 
      both refined and approachable, bringing sophistication to everyday interactions.

      Your Feline nature gives you a sense of self-reliance and discernment, while 
      your Pet orientation shows your ability to form close, comfortable bonds. The 
      Equine aspect adds dignity and measured grace to your approach.

      In professional settings, you excel at creating elegant solutions to practical 
      problems. You have a natural ability to maintain high standards while keeping 
      things accessible and comfortable for others. Your leadership style combines 
      independence with approachability.

      In relationships, you offer a unique blend of sophistication and warmth. You're 
      selective about your associations but deeply loyal to those you choose to let 
      into your inner circle. You create comfortable spaces while maintaining an air 
      of refinement.
    `,
    characteristics: [
      "Natural ability to combine independence with approachability",
      "Elegant problem-solving with practical application",
      "Balanced approach to leadership and relationships",
      "Selective yet warm in social interactions",
      "Ability to create refined yet comfortable environments"
    ],
    advice: `
      To maximize your potential, focus on balancing your need for independence with 
      your ability to create comfortable connections. Remember that perfection isn't 
      always necessary - sometimes comfort and accessibility are more valuable than 
      flawless execution. Your greatest impact comes when you can make sophisticated 
      solutions accessible to others.
    `
  },
  2: {
    title: "Type 2 - Alfred (FPF)",
    detailedDescription: `
      As a Type 2 (Alfred variant), you combine Feline independence with domestic 
      comfort (Pets) and a flair for the extraordinary (Fancy). This creates a 
      personality that is both sophisticated and nurturing, with an artistic touch.

      Your Feline nature provides discernment and autonomy, while your Pet orientation 
      brings warmth and care to your interactions. The Fancy aspect adds creativity 
      and an appreciation for beauty to your approach.

      In professional settings, you excel at creating beautiful solutions that also 
      provide comfort and support. You have a natural ability to enhance environments 
      while keeping them welcoming. Your leadership style combines artistic vision 
      with practical care.

      In relationships, you offer a unique blend of sophistication and nurturing. 
      You're selective about your connections but bring creativity and warmth to 
      those you choose to support. You create spaces that are both beautiful and 
      comforting.
    `,
    characteristics: [
      "Creative approach to caregiving and support",
      "Ability to combine beauty with practical comfort",
      "Selective yet deeply nurturing in relationships",
      "Natural talent for enhancing environments",
      "Balance of independence and artistic expression"
    ],
    advice: `
      To maximize your potential, focus on balancing your creative vision with 
      practical care. Remember that sometimes simple comfort is as valuable as 
      artistic perfection. Your greatest impact comes when you can make beautiful 
      experiences accessible and nurturing for others.
    `
  },
  3: {
    title: "Type 3 - Boris (FWE)",
    detailedDescription: `
      As Type 3 (Boris), you embody a powerful combination of Feline independence, 
      Wild spirit, and Equine grace. This creates a personality that is both untamed 
      and dignified, bringing natural leadership and strategic thinking to your 
      interactions.

      Your Feline-Wild nature provides exceptional independence and strategic thinking, 
      while your Equine aspect adds poise and calculated movement through life's 
      challenges. This combination makes you particularly effective in situations 
      requiring both strength and finesse.

      In professional settings, you excel at leading with both power and grace. You 
      have a natural ability to see strategic opportunities while maintaining dignity 
      in execution. Your leadership style combines wild strength with composed delivery.

      In relationships, you offer a unique blend of protection and elegance. You're 
      fiercely independent but maintain grace in your connections. You create spaces 
      that are both powerful and dignified.
    `,
    characteristics: [
      "Strategic leadership with natural grace",
      "Powerful presence balanced with dignity",
      "Independent thinking with composed execution",
      "Natural ability to combine strength with finesse",
      "Protective yet elegant in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your wild strength with graceful 
      execution. Remember that power is most effective when delivered with poise. 
      Your greatest impact comes when you can combine strategic thinking with 
      elegant implementation.
    `
  },
  4: {
    title: "Type 4 - Boris (FWF)",
    detailedDescription: `
      As Type 4 (Boris variant), you combine Feline independence with Wild spirit 
      and creative sophistication (Fancy). This creates a unique personality that 
      is both untamed and refined, bringing artistic vision to your natural power.

      Your Feline-Wild nature provides exceptional independence and strategic thinking, 
      while your Fancy aspect adds creative flair and appreciation for beauty. This 
      combination makes you particularly effective in situations requiring both 
      innovation and strength.

      In professional settings, you excel at creating unique solutions with bold 
      vision. You have a natural ability to see opportunities for transformation 
      while maintaining artistic standards. Your leadership style combines wild 
      strength with creative expression.

      In relationships, you offer a unique blend of protection and artistry. You're 
      fiercely independent but bring beauty to your connections. You create spaces 
      that are both powerful and aesthetically pleasing.
    `,
    characteristics: [
      "Creative leadership with natural power",
      "Artistic vision combined with strength",
      "Independent thinking with aesthetic sense",
      "Natural ability to combine boldness with beauty",
      "Protective yet artistic in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your wild strength with creative 
      expression. Remember that innovation is most effective when it serves a clear 
      purpose. Your greatest impact comes when you can combine bold thinking with 
      artistic sensitivity.
    `
  },
  5: {
    title: "Type 5 - Alfred (FPE)",
    detailedDescription: `
      As Type 5 (Alfred with Doofs), you blend Feline independence with domestic 
      comfort (Pets) and practical groundedness (Doofs). This creates a personality 
      that is both refined and reliable, bringing systematic thinking to your natural 
      grace.

      Your Feline-Pet nature provides selective sociability and comfort-seeking, 
      while your Doofs aspect adds practical wisdom and methodical approach. This 
      combination makes you particularly effective in creating stable, elegant solutions.

      In professional settings, you excel at developing systematic approaches with 
      refined execution. You have a natural ability to create comfortable systems 
      while maintaining high standards. Your leadership style combines practicality 
      with grace.

      In relationships, you offer a unique blend of stability and sophistication. 
      You're selectively social and create reliable connections. You build spaces 
      that are both comfortable and elegant.
    `,
    characteristics: [
      "Systematic thinking with natural grace",
      "Practical refinement in approach",
      "Selective sociability with reliable presence",
      "Natural ability to combine comfort with elegance",
      "Methodical yet sophisticated in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your practical nature with 
      your refined sensibilities. Remember that elegance can coexist with 
      efficiency. Your greatest impact comes when you can make systematic 
      approaches feel graceful and comfortable.
    `
  },
  6: {
    title: "Type 6 - Alfred (FPF)",
    detailedDescription: `
      As Type 6 (Alfred with Doofs), you combine Feline independence with domestic 
      comfort (Pets) and a unique blend of creative and practical thinking. This 
      creates a personality that is both artistic and grounded.

      Your Feline-Pet nature provides selective warmth and comfort-seeking, while 
      your combination of Fancy and Doofs brings both creativity and practicality 
      to your approach. This makes you particularly effective at creating beautiful 
      yet functional solutions.

      In professional settings, you excel at enhancing environments while maintaining 
      practicality. You have a natural ability to make spaces both beautiful and 
      functional. Your leadership style combines artistic vision with practical wisdom.

      In relationships, you offer a unique blend of creativity and reliability. 
      You're selectively nurturing and bring both beauty and stability to your 
      connections.
    `,
    characteristics: [
      "Creative thinking with practical application",
      "Artistic vision balanced with functionality",
      "Selective nurturing with reliable presence",
      "Natural ability to enhance while maintaining utility",
      "Innovative yet grounded in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your creative vision with 
      practical needs. Remember that beauty and function can coexist harmoniously. 
      Your greatest impact comes when you can make practical solutions feel special 
      and elevated.
    `
  },
  7: {
    title: "Type 7 - Boris (FWE)",
    detailedDescription: `
      As Type 7 (Boris with Doofs), you unite Feline independence with Wild spirit 
      and practical groundedness (Doofs). This creates a powerful personality that 
      combines untamed strength with methodical thinking.

      Your Feline-Wild nature provides exceptional independence and strategic thinking, 
      while your Doofs aspect adds practical wisdom and systematic approach. This 
      combination makes you particularly effective in situations requiring both 
      power and precision.

      In professional settings, you excel at implementing strategic solutions with 
      methodical execution. You have a natural ability to see opportunities while 
      maintaining practical focus. Your leadership style combines wild strength 
      with systematic thinking.

      In relationships, you offer a unique blend of protection and reliability. 
      You're fiercely independent but maintain practical connections. You create 
      spaces that are both powerful and well-structured.
    `,
    characteristics: [
      "Strategic thinking with practical execution",
      "Powerful presence with methodical approach",
      "Independent thinking with systematic focus",
      "Natural ability to combine strength with precision",
      "Protective yet practical in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your wild strength with 
      systematic execution. Remember that power is most effective when channeled 
      through practical methods. Your greatest impact comes when you can combine 
      bold thinking with methodical implementation.
    `
  },
  8: {
    title: "Type 8 - Boris (FWF)",
    detailedDescription: `
      As Type 8 (Boris with Doofs), you combine Feline independence with Wild spirit 
      and a blend of creative and practical thinking. This creates a powerful 
      personality that is both innovative and grounded.

      Your Feline-Wild nature provides exceptional independence and strategic thinking, 
      while your combination of Fancy and Doofs brings both creativity and practicality 
      to your approach. This makes you particularly effective at developing innovative 
      solutions with practical application.

      In professional settings, you excel at creating bold visions with systematic 
      execution. You have a natural ability to see creative opportunities while 
      maintaining practical focus. Your leadership style combines wild strength 
      with innovative thinking.

      In relationships, you offer a unique blend of protection and creativity. 
      You're fiercely independent but bring both innovation and stability to 
      your connections.
    `,
    characteristics: [
      "Creative strategy with practical execution",
      "Powerful presence with innovative approach",
      "Independent thinking with grounded creativity",
      "Natural ability to combine strength with innovation",
      "Protective yet creative in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your creative strength with 
      practical implementation. Remember that innovation is most effective when 
      grounded in reality. Your greatest impact comes when you can combine bold, 
      creative thinking with systematic execution.
    `
  },
  9: {
    title: "Type 9 - George (CPE)",
    detailedDescription: `
      As Type 9 (George), you combine Canine loyalty with domestic companionship (Pets) 
      and Equine grace. This creates a personality that is both dependable and dignified, 
      bringing natural warmth and steady reliability to your interactions.

      Your Canine-Pet nature provides genuine sociability and dedication, while your 
      Equine aspect adds poise and measured consideration to your approach. This 
      combination makes you particularly effective in creating harmonious, supportive 
      environments.

      In professional settings, you excel at building strong, stable teams with grace. 
      You have a natural ability to foster cooperation while maintaining dignity. Your 
      leadership style combines warmth with composed guidance.

      In relationships, you offer a unique blend of loyalty and elegance. You're 
      naturally supportive and bring both stability and refinement to your connections.
    `,
    characteristics: [
      "Natural team builder with graceful approach",
      "Loyal presence with dignified manner",
      "Supportive leadership with composed delivery",
      "Ability to create harmonious environments",
      "Dependable yet refined in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your desire to support others 
      with maintaining your own dignity and boundaries. Remember that your grace 
      under pressure is as valuable as your ability to care for others. Your greatest 
      impact comes when you can combine nurturing support with composed guidance.
    `
  },
  10: {
    title: "Type 10 - George (CPF)",
    detailedDescription: `
      As Type 10 (George variant), you blend Canine loyalty with domestic comfort (Pets) 
      and creative flair (Fancy). This creates a personality that is both nurturing and 
      innovative, bringing warmth and artistic vision to your interactions.

      Your Canine-Pet nature provides genuine care and dedication, while your Fancy 
      aspect adds creativity and aesthetic sensitivity. This combination makes you 
      particularly effective at creating beautiful, supportive environments.

      In professional settings, you excel at fostering creative collaboration. You 
      have a natural ability to inspire while maintaining supportive connections. 
      Your leadership style combines nurturing guidance with artistic vision.

      In relationships, you offer a unique blend of loyalty and creativity. You're 
      naturally supportive and bring both stability and imagination to your connections.
    `,
    characteristics: [
      "Creative nurturer with artistic vision",
      "Loyal support with innovative approach",
      "Inspiring leadership with caring touch",
      "Ability to create beautiful, supportive spaces",
      "Dependable yet imaginative in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your creative vision with your 
      nurturing instincts. Remember that sometimes simple support is as valuable as 
      creative expression. Your greatest impact comes when you can combine artistic 
      vision with genuine care for others.
    `
  },
  11: {
    title: "Type 11 - Daniel (CWE)",
    detailedDescription: `
      As Type 11 (Daniel), you unite Canine loyalty with Wild spirit and Equine grace. 
      This creates a dynamic personality that is both adventurous and dignified, bringing 
      bold leadership with composed delivery.

      Your Canine-Wild nature provides courageous dedication, while your Equine aspect 
      adds poise and calculated strength. This combination makes you particularly 
      effective in situations requiring both bold action and graceful execution.

      In professional settings, you excel at leading with both power and dignity. You 
      have a natural ability to inspire while maintaining composure. Your leadership 
      style combines adventurous spirit with elegant guidance.

      In relationships, you offer a unique blend of protection and refinement. You're 
      fiercely loyal but maintain grace in your connections.
    `,
    characteristics: [
      "Bold leadership with graceful delivery",
      "Adventurous spirit with composed manner",
      "Courageous guidance with elegant execution",
      "Ability to inspire while maintaining dignity",
      "Protective yet refined in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your adventurous spirit with 
      graceful execution. Remember that strength is most impactful when delivered 
      with poise. Your greatest impact comes when you can combine bold leadership 
      with elegant guidance.
    `
  },
  12: {
    title: "Type 12 - Daniel (CWF)",
    detailedDescription: `
      As Type 12 (Daniel variant), you combine Canine loyalty with Wild spirit and 
      creative sophistication (Fancy). This creates a unique personality that is both 
      adventurous and artistic, bringing bold vision with creative expression.

      Your Canine-Wild nature provides courageous dedication, while your Fancy aspect 
      adds creativity and aesthetic sensitivity. This combination makes you particularly 
      effective at inspiring innovative solutions with bold execution.

      In professional settings, you excel at leading creative initiatives with courage. 
      You have a natural ability to inspire while maintaining artistic standards. Your 
      leadership style combines adventurous spirit with creative vision.

      In relationships, you offer a unique blend of protection and artistry. You're 
      fiercely loyal but bring beauty and imagination to your connections.
    `,
    characteristics: [
      "Creative leadership with bold vision",
      "Adventurous spirit with artistic sense",
      "Courageous guidance with creative expression",
      "Ability to inspire innovative solutions",
      "Protective yet artistic in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your creative vision with your 
      protective instincts. Remember that innovation is most powerful when it serves 
      and protects others. Your greatest impact comes when you can combine bold 
      leadership with artistic sensitivity.
    `
  },
  13: {
    title: "Type 13 - George (CPE)",
    detailedDescription: `
      As Type 13 (George with Doofs), you combine Canine loyalty with domestic comfort 
      (Pets) and practical groundedness (Doofs). This creates a reliable personality 
      that is both nurturing and methodical.

      Your Canine-Pet nature provides genuine care and dedication, while your Doofs 
      aspect adds practical wisdom and systematic approach. This combination makes you 
      particularly effective at creating stable, supportive environments.

      In professional settings, you excel at building reliable systems with care. You 
      have a natural ability to support while maintaining efficiency. Your leadership 
      style combines nurturing guidance with practical wisdom.

      In relationships, you offer a unique blend of loyalty and reliability. You're 
      naturally supportive and bring both stability and practicality to your connections.
    `,
    characteristics: [
      "Practical nurturer with systematic approach",
      "Loyal support with methodical execution",
      "Caring leadership with efficient delivery",
      "Ability to create stable, supportive systems",
      "Dependable and grounded in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your nurturing instincts with 
      practical efficiency. Remember that systematic care can be as valuable as 
      spontaneous support. Your greatest impact comes when you can combine genuine 
      care with practical solutions.
    `
  },
  14: {
    title: "Type 14 - George (CPF)",
    detailedDescription: `
      As Type 14 (George with Doofs), you unite Canine loyalty with domestic comfort 
      (Pets) and a blend of creative and practical thinking. This creates a personality 
      that is both nurturing and innovative, while maintaining groundedness.

      Your Canine-Pet nature provides genuine care and dedication, while your combination 
      of Fancy and Doofs brings both creativity and practicality to your approach. This 
      makes you particularly effective at creating beautiful, functional support systems.

      In professional settings, you excel at developing creative solutions with practical 
      care. You have a natural ability to enhance while maintaining efficiency. Your 
      leadership style combines nurturing guidance with innovative thinking.

      In relationships, you offer a unique blend of loyalty and creativity, grounded 
      in practicality. You're naturally supportive and bring both imagination and 
      stability to your connections.
    `,
    characteristics: [
      "Creative nurturer with practical approach",
      "Loyal support with innovative solutions",
      "Caring leadership with creative efficiency",
      "Ability to enhance while maintaining function",
      "Dependable yet imaginative in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your creative care with practical 
      implementation. Remember that practical support can be as valuable as creative 
      expression. Your greatest impact comes when you can combine innovative nurturing 
      with functional solutions.
    `
  },
  15: {
    title: "Type 15 - Daniel (CWE)",
    detailedDescription: `
      As Type 15 (Daniel with Doofs), you combine Canine loyalty with Wild spirit 
      and practical groundedness (Doofs). This creates a powerful personality that 
      is both adventurous and methodical.

      Your Canine-Wild nature provides courageous dedication, while your Doofs aspect 
      adds practical wisdom and systematic approach. This combination makes you 
      particularly effective at implementing bold visions with careful planning.

      In professional settings, you excel at leading strategic initiatives with 
      practical execution. You have a natural ability to inspire while maintaining 
      efficiency. Your leadership style combines adventurous spirit with methodical 
      implementation.

      In relationships, you offer a unique blend of protection and reliability. You're 
      fiercely loyal and bring both excitement and stability to your connections.
    `,
    characteristics: [
      "Strategic leadership with practical execution",
      "Adventurous spirit with methodical approach",
      "Courageous guidance with systematic delivery",
      "Ability to inspire while maintaining structure",
      "Protective yet grounded in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your adventurous spirit with 
      systematic execution. Remember that bold visions require careful planning for 
      success. Your greatest impact comes when you can combine courageous leadership 
      with practical implementation.
    `
  },
  16: {
    title: "Type 16 - Daniel (CWF)",
    detailedDescription: `
      As Type 16 (Daniel with Doofs), you blend Canine loyalty with Wild spirit and 
      a unique combination of creative and practical thinking. This creates a dynamic 
      personality that is both adventurous and innovative, while maintaining groundedness.

      Your Canine-Wild nature provides courageous dedication, while your combination 
      of Fancy and Doofs brings both creativity and practicality to your approach. 
      This makes you particularly effective at developing innovative solutions with 
      systematic implementation.

      In professional settings, you excel at leading creative initiatives with 
      practical execution. You have a natural ability to inspire while maintaining 
      efficiency. Your leadership style combines adventurous spirit with innovative 
      yet grounded thinking.

      In relationships, you offer a unique blend of protection and creativity, anchored 
      in practicality. You're fiercely loyal and bring both imagination and stability 
      to your connections.
    `,
    characteristics: [
      "Creative leadership with practical execution",
      "Adventurous spirit with grounded innovation",
      "Courageous guidance with systematic creativity",
      "Ability to inspire while maintaining structure",
      "Protective yet imaginative in relationships"
    ],
    advice: `
      To maximize your potential, focus on balancing your creative vision with 
      practical implementation. Remember that innovative solutions require systematic 
      execution for success. Your greatest impact comes when you can combine 
      creative leadership with practical follow-through.
    `
  }
};

// Helper function to get description
export function getTypeDescription(type: number): TypeDescription | undefined {
  return typeDescriptionDetails[type];
} 