import React, { useState, useCallback, useEffect } from 'react';
import { AnalysisResult } from '../types';
import { getTypeDescription } from '../data/typeDescriptionDetails';
import FeedbackPopup from './FeedbackPopup';
import ThankYouPage from './ThankYouPage';

interface ResultsProps {
  results: AnalysisResult | null;
  onFeedbackSubmit: (feedback: string) => void;
  userData: {
    name: string;
    sessionId?: string;
  };
}

const Results: React.FC<ResultsProps> = ({ results, onFeedbackSubmit, userData }) => {
  const [selectedText, setSelectedText] = useState('');
  const [popupPosition, setPopupPosition] = useState({
    x: 0,
    y: 0,
    containerTop: 0,
    containerHeight: 0,
    isInAdviceSection: false
  });
  const [showPopup, setShowPopup] = useState(false);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [sessionId] = useState(() => userData.sessionId || crypto.randomUUID());

  const handleTextSelection = useCallback(() => {
    const selection = window.getSelection();
    if (selection && selection.toString().trim()) {
      // Get viewport dimensions
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Calculate center position
      const x = (viewportWidth - 320) / 2; // 320px is the popup width
      const y = window.scrollY + (viewportHeight / 2) - 200; // 200px is roughly half the popup height

      setSelectedText(selection.toString().trim());
      setPopupPosition({
        x,
        y,
        containerTop: 0,
        containerHeight: 0,
        isInAdviceSection: false
      });
      setShowPopup(true);
    }
  }, []);

  const handleClosePopup = useCallback(() => {
    // Clear the selection when closing the popup
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
    setShowPopup(false);
  }, []);

  const handleFeedbackSubmit = async (feedback: {
    selectedText: string;
    comments: string;
    agree: boolean;
    disagree: boolean;
  }) => {
    console.log('Attempting to send feedback telemetry:', {
      sessionId,
      name: userData.name,
      feedback
    });

    try {
      const response = await fetch('/api/telemetry/results-feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId,
          name: userData.name,
          highlightedText: feedback.selectedText,
          comment: feedback.comments,
          feedbackType: feedback.agree ? 'agree' : 'disagree'
        }),
      });

      const data = await response.json();
      console.log('Feedback telemetry response:', data);

      if (!response.ok) {
        throw new Error(`API returned ${response.status}: ${JSON.stringify(data)}`);
      }
    } catch (error) {
      console.error('Failed to send results feedback telemetry:', error);
    }

    const feedbackString = JSON.stringify(feedback);
    onFeedbackSubmit(feedbackString);
    setShowPopup(false);
  };

  const handleSurveyEnd = async () => {
    console.log('Attempting to send survey ended telemetry:', {
      sessionId,
      name: userData.name
    });

    try {
      const response = await fetch('/api/telemetry/survey-ended', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId,
          name: userData.name
        }),
      });

      const data = await response.json();
      console.log('Survey ended telemetry response:', data);

      if (!response.ok) {
        throw new Error(`API returned ${response.status}: ${JSON.stringify(data)}`);
      }
    } catch (error) {
      console.error('Failed to send survey ended telemetry:', error);
    }
    setSurveyCompleted(true);
  };

  if (surveyCompleted) {
    return <ThankYouPage />;
  }

  if (!results) {
    return <div>No results available</div>;
  }

  const detailedDescription = getTypeDescription(results.type);

  return (
    <div
      className="max-w-2xl mx-auto"
      onMouseUp={handleTextSelection}
    >
      {detailedDescription && (
        <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
          <h2 className="text-2xl font-bold mb-4">{detailedDescription.title}</h2>

          <div className="prose max-w-none mb-6">
            <p className="text-gray-700 whitespace-pre-line select-text">
              {detailedDescription.detailedDescription}
            </p>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-3">Key Characteristics</h3>
            <ul className="list-disc pl-5 space-y-2 select-text">
              {detailedDescription.characteristics.map((char, index) => (
                <li key={index} className="text-gray-700">
                  <span className="select-text">{char}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="bg-blue-50 p-6 rounded-lg">
            <h3 className="text-xl font-semibold mb-3 text-blue-800">Personal Growth Advice</h3>
            <p className="text-gray-700 whitespace-pre-line select-text">
              {detailedDescription.advice}
            </p>
          </div>
        </div>
      )}

      <div className="max-w-2xl mx-auto">
        <h2 className="text-2xl font-bold mb-4">Your Animal Profile Results</h2>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Final Results</h3>
          <p><strong>Type:</strong> {results.type}</p>
          <p><strong>QPrefD:</strong> {results.QPrefD}</p>
          <p><strong>QPrefL:</strong> {results.QPrefL}</p>
          <p><strong>QStrD:</strong> {results.QStrD}</p>
          <p><strong>QStrL:</strong> {results.QStrL}</p>
          <p><strong>MPolDe:</strong> {results.MPolDe}</p>
          <p><strong>MPolLe:</strong> {results.MPolLe}</p>
          <p><strong>Q:</strong> {results.Q}</p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-bold mb-2">Intermediate Values</h3>
          <h4 className="text-lg font-semibold mt-2">Section 1</h4>
          <p><strong>s1Dogs:</strong> {results.s1dogs}</p>
          <p><strong>s1Cats:</strong> {results.s1cats}</p>
          <p><strong>s1Wolves:</strong> {results.s1wolves}</p>
          <p><strong>s1Lions:</strong> {results.s1lions}</p>

          <h4 className="text-lg font-semibold mt-2">Section 2</h4>
          <p><strong>s2Dogs:</strong> {results.s2dogs}</p>
          <p><strong>s2Cats:</strong> {results.s2cats}</p>
          <p><strong>s2Wolves:</strong> {results.s2wolves}</p>
          <p><strong>s2Lions:</strong> {results.s2lions}</p>

          <h4 className="text-lg font-semibold mt-2">Section 3</h4>
          <p><strong>s3Kangaroo:</strong> {results.s3kangaroo}</p>
          <p><strong>s3Giraffe:</strong> {results.s3giraffe}</p>
          <p><strong>s3Horses:</strong> {results.s3horses}</p>
          <p><strong>s3Unicorns:</strong> {results.s3unicorns}</p>

        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Preference Pairs</h3>
          <p><strong>PrefPairDe:</strong> {results.PrefPairDe}</p>
          <p><strong>PrefPairLe:</strong> {results.PrefPairLe}</p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Confidence Values</h3>
          <p><strong>PrefPairDeConVal:</strong> {results.PrefPairDeConVal}</p>
          <p><strong>PrefPairLeConVal:</strong> {results.PrefPairLeConVal}</p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Directional Accuracy</h3>
          <p><strong>PrefPairDeConDir:</strong> {results.PrefPairDeConDir ? 'True' : 'False'}</p>
          <p><strong>PrefPairLeConDir:</strong> {results.PrefPairLeConDir ? 'True' : 'False'}</p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Strength Pairs</h3>
          <p><strong>StrPairEtlo:</strong> {results.StrPairEtlo}</p>
          <p><strong>StrPairSein:</strong> {results.StrPairSein}</p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Strength Pair Confidence</h3>
          <p><strong>StrPairEtloConVar:</strong> {results.StrPairEtloConVar}</p>
          <p><strong>StrPairSeinConVar:</strong> {results.StrPairSeinConVar}</p>
        </div>
      </div>

      <div className="mt-12 mb-16 text-center">
        <button
          onClick={handleSurveyEnd}
          className="bg-blue-600 text-white px-8 py-3 rounded-md text-lg font-semibold hover:bg-blue-700 transition-colors"
        >
          End Survey
        </button>
      </div>

      {showPopup && (
        <FeedbackPopup
          selectedText={selectedText}
          position={popupPosition}
          onClose={handleClosePopup}
          onSubmit={handleFeedbackSubmit}
        />
      )}
    </div>
  );
};

export default Results;